import logo from "./logo.webp"

const clientData = {
    client_entity: 36,
    attorney_firm: 'Chermol and Fishman',
    attorney_name: 'Sam Fishman',
    attorney_number: '877-439-9717',
    attorney_email: 'sam@ssihelp.us',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_chermol_and_fishman+(720p).mp4',
    mainColor: '#1F263A',
    secondaryColor: '#3D3E3E',
    siteLink: 'https://myphiladelphiadisabilitylawyer.com',
    logo
}

export default clientData